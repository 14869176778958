import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useRfq() {
  const rfqs = ref([])
  const rfq = ref({})
  const loading = ref(false)
  const searchRfq = ref(null)

  const getRfqs = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/rfqs/filter', params)
        .then(response => {
          const { data } = response.data
          rfqs.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getRfq = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/rfqs/${id}`)
        .then(response => {
          const { data } = response
          rfq.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchRfq,
    rfqs,
    rfq,

    // functions
    getRfqs,
    getRfq,
  }
}
