<template>
  <v-card>
    <v-card-title>Request for Quotations</v-card-title>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchRfqs"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="create"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New RFQ</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="rfqs"
        :options.sync="options"
        :server-items-length="totalRfq"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- quotation_number -->
        <template #[`item.quotation_number`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'rfqs-edit', params: { id: item.id } }"
            >
              {{ item.quotation_number }}
            </router-link>
          </div>
          <v-chip
            v-if="item.lot_supplier"
            color="warning"
            small
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiStar }}
            </v-icon>
            Awarded by lot
          </v-chip>
        </template>

        <template #[`item.abc`]="{ item }">
          <p>{{ (item.abc).toLocaleString("en-US") }}</p>
        </template>

        <!-- suppliers -->
        <template #[`item.suppliers`]="{ item }">
          <p v-if="item.suppliers">
            <v-chip
              v-for="(supplier, key) in item.suppliers"
              :key="key"
              class="ma-2"
              outlined
              small
              :color="(item.lot_supplier && item.lot_supplier.id === supplier.supplier.id) || awardedSupplier(item, supplier) ? 'warning' : 'primary'"
              @click="
                $router.push({ name: 'rfq-suppliers-edit', params: { id: item.id, rfq_supplier_id: supplier.id } })
              "
            >
              <v-icon
                v-if="(item.lot_supplier && item.lot_supplier.id === supplier.supplier.id) || awardedSupplier(item, supplier)"
                small
                color="warning"
                class="mr-1"
              >
                {{ icons.mdiStar }}
              </v-icon>
              {{ supplier.supplier.supplier_name }}
            </v-chip>
            <v-btn
              class="mt-2"
              x-small
              color="primary"
              fab
              @click="$router.push({ name: 'rfq-suppliers-create', params: { id: item.id } })"
            >
              <v-icon x-small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </p>
        </template>

        <!-- abstract -->
        <template #[`item.abstract`]="{ item }">
          <v-btn
            class="mt-2"
            x-small
            color="primary"
            outlined
            @click="$router.push({ name: 'rfq-suppliers-abstract', params: { id: item.id } })"
          >
            <span>Open</span>
          </v-btn>
        </template>

        <!-- quotation_date  -->
        <template #[`item.quotation_date`]="{ item }">
          <span>{{ resolveDate(item.quotation_date) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="duplicateRfq(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Duplicate</span>
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="deleteItemConfirm(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title> Are you sure you want to delete this item? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialogDelete = !dialogDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItem"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiStar,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'
import useUIResolver from '../useUIResolver'
import useRfq from './useRfq'

export default {
  setup() {
    const rfqData = ref({})
    const searchQuery = ref('')
    const totalRfq = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const { rfqHeaders, resolveDate } = useUIResolver()
    const { rfqs, getRfqs } = useRfq()

    const create = () => {
      router.push({ name: 'rfq-create' })
    }

    const fetchRfqs = () => {
      getRfqs({ ...options.value, ...{ searchQuery: searchQuery.value } }).then(response => {
        totalRfq.value = response.data.total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    const awardedSupplier = (item, supplier) => item.purchase_orders.length > 0 && item.purchase_orders.find(o => o.supplier_id === supplier.supplier.id)

    onMounted(() => {
      headers.value = rfqHeaders()

      fetchRfqs()
    })

    watch(options, fetchRfqs)

    const clearSearch = () => {
      searchQuery.value = null
    }

    const editItem = item => {
      router.push({ name: 'rfqs-edit', params: { id: item.id } })
    }

    const deleteItem = () => {
      axios.delete(`/rfqs/${rfqData.value.id}`).then(() => {
        fetchRfqs()
        dialogDelete.value = false
      })
    }

    const duplicateRfq = item => {
      axios.post(`/rfqs/duplicate/${item.id}`, item)
        .then(() => {
          fetchRfqs()
        })
    }

    const deleteItemConfirm = item => {
      rfqData.value = item
      dialogDelete.value = true
    }

    return {
      dialogDelete,
      rfqData,
      headers,
      searchQuery,
      totalRfq,
      footerProps,
      options,
      resolveDate,
      create,

      // functions
      duplicateRfq,
      clearSearch,
      awardedSupplier,

      rfqs,
      fetchRfqs,
      getRfqs,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiStar,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
      },
    }
  },
}
</script>
