var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Request for Quotations")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchRfqs.apply(null, arguments)},"click:clear":_vm.clearSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New RFQ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rfqs,"options":_vm.options,"server-items-length":_vm.totalRfq,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.quotation_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{staticClass:"font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'rfqs-edit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.quotation_number)+" ")])],1),(item.lot_supplier)?_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiStar)+" ")]),_vm._v(" Awarded by lot ")],1):_vm._e()]}},{key:"item.abc",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s((item.abc).toLocaleString("en-US")))])]}},{key:"item.suppliers",fn:function(ref){
var item = ref.item;
return [(item.suppliers)?_c('p',[_vm._l((item.suppliers),function(supplier,key){return _c('v-chip',{key:key,staticClass:"ma-2",attrs:{"outlined":"","small":"","color":(item.lot_supplier && item.lot_supplier.id === supplier.supplier.id) || _vm.awardedSupplier(item, supplier) ? 'warning' : 'primary'},on:{"click":function($event){return _vm.$router.push({ name: 'rfq-suppliers-edit', params: { id: item.id, rfq_supplier_id: supplier.id } })}}},[((item.lot_supplier && item.lot_supplier.id === supplier.supplier.id) || _vm.awardedSupplier(item, supplier))?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiStar)+" ")]):_vm._e(),_vm._v(" "+_vm._s(supplier.supplier.supplier_name)+" ")],1)}),_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","color":"primary","fab":""},on:{"click":function($event){return _vm.$router.push({ name: 'rfq-suppliers-create', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],2):_vm._e()]}},{key:"item.abstract",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'rfq-suppliers-abstract', params: { id: item.id } })}}},[_c('span',[_vm._v("Open")])])]}},{key:"item.quotation_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.resolveDate(item.quotation_date)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.duplicateRfq(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentCopy)+" ")]),_c('span',[_vm._v("Duplicate")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItemConfirm(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialogDelete = !_vm.dialogDelete}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItem}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }